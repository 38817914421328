import React, { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import Screenshot from "../components/buttons/Screenshot";
import { data } from "../data/db";
import "../css/Cards.css";
import "../css/Animations.css";
import "../css/HotCards.css";
import { LanguageContext, languages } from "../context/TranslationContext";
import { languageByNavigator } from "../utils/language";

export default function Details() {
    window.scrollTo({top: 0});
  const { id } = useParams<{ id: string }>();
  const { setLang, lang } = useContext(LanguageContext);
  const onLanguageSelect = (lang: languages) => {
    setLang!(lang);
  };
  const v = data[Number(Number(id).toFixed(0))];
  const colors = [
    "linear-gradient(to right bottom, #1fefe6, #2d7dd0)",
    "linear-gradient(to bottom right, #e7ab86, #e78f86)",
    "linear-gradient(to bottom right, #ffc900, #ff5f00)",

    "linear-gradient(to bottom right, #f3e41e, #6bd02d, #25dfd6)",
    "linear-gradient(to bottom right, #ff6e60, #e7210d)",
    "linear-gradient(to right bottom, #1f2dda, #da1f86)",
  ];
  const [color, setColor] = useState(colors[0]);

  return (
    <section className="container row mx-auto gy-4">
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2048720703689521"
     crossOrigin="anonymous"></script>
      <div className="col-md-5">
      <div className="text-center" style={{marginBottom: "10px"}}>
          <small className="grey"><b>TIP:</b> press the instagram or share icon to export</small>
        </div>
        <div
          className="card h-100 noise-light scale-in-center fit-height-content"
          id={id}
          style={{ backgroundImage: color }}
        >
          <div>
          <Screenshot
            className="hot-share-icon"
            id={id}
            removeRadius
            removeShadow
          />
          <Screenshot
            className="hot-insta-icon"
            id={id}
            removeShadow
            removeRadius
            customIcon="bi bi-instagram"
            customScreenshotClass="insta-center"
          />
          <br />
          <div className="w-100 d-flex"><img
            src="/logoishdesign.png"
            className="card-img-top round-cur-logo"
            alt=""
          /></div>
          <figure className="card-body text-center">
            <blockquote className="blockquote">
              <p className="white fw-bold">{v.title}</p>
            </blockquote>

            <figcaption className="blockquote-footer white">
              {languageByNavigator(v, lang)}
            </figcaption>

            <i className="white">papiamentu.org</i>
          </figure>
          <br />
          </div>
        </div>

        <br />
        <h5 className="grey">
          <i className="bi bi-translate"></i> Change language
        </h5>
        <div
          className="btn-group mx-auto"
          style={{ width: "100%" }}
          role="group"
          aria-label="Language selection buttons"
        >
          <button
            onClick={() => onLanguageSelect(languages.ENGLISH)}
            type="button"
            className={`btn btn-lg white ${ !v.english && 'disabled'}`}
            style={{ backgroundImage: colors[0] }}
            aria-pressed={lang === languages.ENGLISH}
          >
            English
          </button>
          <button
            onClick={() => onLanguageSelect(languages.DUTCH)}
            type="button"
            className={`btn btn-lg white ${ !v.dutch && 'disabled'}`}
            style={{ backgroundImage: colors[1] }}
            aria-pressed={lang === languages.DUTCH}
          >
            Dutch
          </button>
          <button
            onClick={() => onLanguageSelect(languages.SPANISH)}
            type="button"
            className={`btn btn-lg white ${ !v.spanish && 'disabled'}`}
            style={{ backgroundImage: colors[4] }}
            aria-pressed={lang === languages.SPANISH}
          >
            Spanish
          </button>
        </div>
      </div>

      <div className="col-md-7">
        <h4 className="grey">
          <i className="bi bi-eyedropper"></i> Choose color
        </h4>
        <div className="row gy-4">
          {colors.map((color, i) => (
            <div className="col-md-4 col-12" key={`color-${i}`}>
              <div
                onClick={() => setColor(color)}
                className="card"
                style={{ backgroundImage: color }}
              ></div>
            </div>
          ))}
        </div>
      </div>

      <div className="col-12 grey">
        <h5 >All translations</h5>
        <p><b>English:</b> {v?.english || "MISSING"}</p>
        <p><b>Spanish:</b> {v?.spanish || "MISSING"}</p>
        <p><b>Dutch:</b> {v?.dutch || "MISSING"}</p>
        <p><b>Papiamentu:</b> {v?.papiamentu || "MISSING"}</p>
      </div>
    </section>
  );
}
