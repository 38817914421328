import React, { useContext, useRef, useState } from "react";
import { useHistory } from "react-router";
import { LanguageContext } from "../context/TranslationContext";
import { Random } from "../utils/array";
import { languageByNavigator } from "../utils/language";
import Screenshot from "./buttons/Screenshot";
import InfiniteScroll from 'react-infinite-scroll-component';

type renderable =  string | number | undefined | null;
interface InfiniteCardListProps<T extends Record<string, renderable>> {
    data: T[]
}
export default function InfiniteCardList<T extends Record<string, renderable>>(props: InfiniteCardListProps<T>){
    const bgColors = [
        "linear-gradient(to right bottom, #1fefe6, #2d7dd0)",
        "linear-gradient(to bottom right, #e7ab86, #e78f86)",
        "linear-gradient(to bottom right, #ffc900, #ff5f00)",
        "linear-gradient(to bottom right, #f3e41e, #6bd02d, #25dfd6)",
        "linear-gradient(to bottom right, #ff6e60, #e7210d)",
        "linear-gradient(to right bottom, #1f2dda, #da1f86)",
      ];
      const random = new Random(bgColors);
      const {lang} = useContext(LanguageContext);
      let history = useHistory();
      const [loaded, setLoaded] = useState(props.data.slice(0, 8));
      let cursor = useRef(1);

      const fetchNext = () => {
        let at = cursor.current * 8;
        setLoaded(loaded.concat(props.data.slice(at, at + 8)));
        cursor.current++;
      }
      return (
        <InfiniteScroll
          style={{
            overflowY: "auto",
            overflowX: "hidden"
          }}
          dataLength={loaded.length}
          next={fetchNext}
          hasMore={true}
          loader={<div className="h4  grey text-center">Loading...</div>}
        >
        <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4  g-4">
        
        {loaded.map((v, i) => {
              let id = `hotcard-${i}`;
              if(!v.color) (v as any).color = random.randomNoLastRepeat();
              if(!v.id) (v as any).id = i;
            return (
              <div className="col" key={id} >
                <div tabIndex={0}
                aria-label={`Translation card with id number ${id}`}
                 className="card h-100 noise-light scale-in-center" id={id} style={{backgroundImage: `${v.color}`}}>
                  <div>
                    {/* <Screenshot className="hot-share-icon" id={id} removeRadius removeShadow/> */}
                    <div
                    role="button"
                    aria-label="Open settings for this translation card"
                    tabIndex={0}
                     onClick={()=>history.push(`/details/${v.id}`)}className="hot-share-icon remove-on-ss clickable"><i className="bi bi-tools"></i></div>
                    <Screenshot className="hot-insta-icon" id={id} removeShadow removeRadius customIcon="bi bi-instagram" customScreenshotClass="insta-center"/>
                  <div className="w-100 d-flex"><img
                    src="logoishdesign.png"
                    className="card-img-top round-cur-logo clickable mx-auto"
                    alt=""
                    role="button"
                    aria-label="Open settings for this translation card"
                    onClick={()=>history.push(`/details/${v.id}`)}
                    tabIndex={0}
                  /></div>
                  <figure 
                  role="button"
                  aria-label="Open settings for this translation card"
                   onClick={()=>history.push(`/details/${v.id}`)} className="card-body text-center clickable">
                    <blockquote className="blockquote">
                      <p className="white fw-bold">{v.title}</p>
                    </blockquote>
    
                    <figcaption className="blockquote-footer white">
                        {languageByNavigator(v, lang)}
                    </figcaption>
    
                    <i className="white">papiamentu.org</i>
                  </figure>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </InfiniteScroll>
      
      );
}