import React from "react";
import "../App.css";
import "../css/Cards.css";
import { data } from "../data/db";
import AnimatedFire from "../components/misc/AnimatedFire";
import { Link } from "react-router-dom";
import InfiniteCardList from "../components/InfiniteCardList";
import { Random } from "../utils/array";

export default function Home() {
  return (
    <div>
      <section className="container">
        <div className="py-5 px-md-2 px-lg-4 mb-4 bg-light card-rounding noise-verylight">
          <div className="container-fluid py-md-3 py-lg-5 row">
            <div className="col-md-9 col-sm-12">
              <h1 className="display-5 fw-bold">Papiamentu</h1>
              <p className="col-md-8 fs-4">
                Papiamentu is a Spanish-Portuguese-based creole language spoken
                in Curaçao. This is a space for sharing sentences and cultural slangs.
              </p>
              <Link to="/sources" className="btn btn-lg bg-blue white" type="button">
                Our sources
              </Link>
              {' '}
              <div className="d-md-none" style={{height: '10px'}}>
                <br />
              </div>
              <Link to={`/details/${Random.findRandomIdx(data.length)}`} className="btn btn-lg bg-pink white" type="button">
                Random sentence
              </Link>
            </div>

            <div className="col-md-3 remove-on-mobile">
              <img
                className="shadow rounded fade-in"
                style={{ width: "100%" }}
                src="sapate_cover_front.jpg"
                alt="book cover"
              />
            </div>
          </div>
        </div>
      </section>

      <br />
      <section className="container">
        <h2 className="text-center red page-divider" style={{marginBottom: "-3px"}}>
          <AnimatedFire childStyles={{ right: "calc(50% + 42px)" }} />
          List
          <AnimatedFire childStyles={{ left: "calc(50% + 42px)" }} />
        </h2>
        <div className="text-center" style={{marginBottom: "10px"}}>
          <small className="grey"><b>TIP:</b> press on a card to open the customization page</small>
        </div>
        <InfiniteCardList data={data} />
      </section>
    </div>
  );
}
