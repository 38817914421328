import React, { useState } from "react";

export default function AddTranslation() {
  const [inputs, setInputs] = useState({});
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs((values) => ({ ...values, [name]: value }));
  };

  const onHandleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log(inputs);
  };
  return (
    <section className="container">
      <form onSubmit={onHandleSubmit}>
        <div className="mb-3">
          <label htmlFor="titleInput" className="form-label">
            Title <span className="text-danger">*</span>
          </label>
          <input
            className="form-control"
            id="titleInput"
            name="titleInput"
            aria-describedby="titleInputHelp"
            onChange={handleChange}
          />
          <div id="titleInputHelp" className="form-text">
            Title should be in papiamentu.
          </div>
        </div>

        <div className="mb-3">
          <label htmlFor="englishInput" className="form-label">
            English
          </label>
          <input
            className="form-control"
            id="englishInput"
            name="englishInput"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="spanishInput" className="form-label">
            Spanish
          </label>
          <input
            className="form-control"
            id="spanishInput"
            name="spanishInput"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="dutchInput" className="form-label">
            Dutch
          </label>
          <input
            className="form-control"
            id="dutchInput"
            name="dutchInput"
            onChange={handleChange}
          />
        </div>

        <div className="mb-3">
          <label htmlFor="papiamentuInput" className="form-label">
            Papiamentu
          </label>
          <input
            className="form-control"
            id="papiamentuInput"
            name="papiamentuInput"
            onChange={handleChange}
          />
        </div>

        <button type="submit" className="btn btn-primary">
          Submit
        </button>
      </form>
    </section>
  );
}
