import React, { createContext, useState } from 'react'

type ContextProps = { 
  lang: string,
  setLang: React.Dispatch<React.SetStateAction<languages>>,
};
export const LanguageContext = createContext<Partial<ContextProps>>({})

export enum languages {ENGLISH = "en", DUTCH = "nl", SPANISH = "la", UNDEFINED = ""}
export const LanguageContextProvider = (props: any) => {
  const [lang, setLang] = useState<languages>(languages.UNDEFINED);

  return <LanguageContext.Provider value={{ lang, setLang }}>{props.children}</LanguageContext.Provider>
}