import React, { useRef } from "react";
import * as htmlToImage from "html-to-image";

interface ScreenshotProps {
  id: string;
  className?: string;
  customIcon?: string;
  customScreenshotClass?: string;
  removeRadius?: boolean;
  removeShadow?: boolean;
  removeBGImage?: boolean;
}
export default function Screenshot(props: ScreenshotProps) {
  const thisRef = useRef<HTMLElement | null>(null);

  const handleClick = async () => {
    let toCapture = document.getElementById(props.id)!;
    let removeList = toCapture.getElementsByClassName("remove-on-ss");
    for(let i = 0;  i < removeList.length; i++){
        (removeList.item(i) as HTMLElement).style.visibility = "hidden";
    }

    let extraStyles: Record<string, any> = {};
    if (props.customScreenshotClass) {
      toCapture.className += ` ${props.customScreenshotClass}`;
    }
    if (props.removeBGImage) extraStyles.backgroundImage = "none";
    
    await htmlToImage.toPng(toCapture, {style: extraStyles}).then(function (dataUrl) {
      const a = document.createElement("a");
      a.setAttribute("download", "my-image.png");
      a.setAttribute("href", dataUrl);
      a.click();
      a.remove();
    });
    if (props.customScreenshotClass) {
        toCapture.className = toCapture.className.replace(` ${props.customScreenshotClass}`, "") ;
    }
    for(let i = 0;  i < removeList.length; i++){
        (removeList.item(i) as HTMLElement).style.visibility = "visible";
    }

  };
  return (
    <>
      <i
        ref={thisRef}
        onClick={() => handleClick()}
        data-html2canvas-ignore
        role="button"
        tabIndex={0}
        aria-label="Take a screenshot of this element and save it"
        className={`remove-on-ss clickable ${props.customIcon || "bi bi-share-fill"} ${
          props.className
        }`}
      ></i>
    </>
  );
}
