import React, { ChangeEvent, useContext } from "react";
import { useHistory } from "react-router-dom";
import { SearchContext } from "../context/SearchContext";
import { LanguageContext, languages } from "../context/TranslationContext";

import "../css/Nav.css";
import { data } from "../data/db";
import { Random } from "../utils/array";
export default function Nav() {
  let history = useHistory();
  
  const {setSearchV} = useContext(SearchContext);
  const {setLang} = useContext(LanguageContext);
  const onSearchSubmit = () => {
    history.push("/search");
  };
  const onSearchVUpdate = (e: ChangeEvent<HTMLInputElement>) => {
    if(history.location.pathname !== "/search"){
      history.push("/search");
    }
    setSearchV!(e.target.value);
  };

  const onLanguageSelect = (lang: languages)=>{
    setLang!(lang);
  }
  return (
    <section className="nav-container container-fluid sticky-top">
      <nav className="container navbar navbar-expand-lg ">
        <div className="container-fluid">
          <a tabIndex={0} className="navbar-brand" href="/" aria-label="Home button">
            <i style={{ fontSize: "30px" }} className="bi bi-house blue"></i>
          </a>

          <ul className="navbar-nav navbar-brand me-auto flex-row">
          <li tabIndex={0} onClick={()=>history.push(`/details/${Random.findRandomIdx(data.length)}`)} 
          className="nav-item clickable">
              <div className="nav-link">
                <i style={{ fontSize: "25px" }} className="bi bi-dice-3 blue"></i>
              </div>
            </li>

            <li className="nav-item dropdown">
              <div
                className="nav-link dropdown-toggle"
                id="navbarDropdownMenuLink"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
                aria-label="Go to a random sentence"
                tabIndex={0}
              >
                <i
                  style={{ fontSize: "25px" }}
                  className="bi bi-translate blue"
                ></i>
              </div>
              <ul
                className="dropdown-menu"
                aria-labelledby="navbarDropdownMenuLink"
              >
                <li>
                  <div tabIndex={0} aria-label="Select english as a language" onClick={()=>onLanguageSelect(languages.ENGLISH)} className="dropdown-item">English</div>
                </li>
                <li>
                  <div tabIndex={0} aria-label="Select dutch as a language" onClick={()=>onLanguageSelect(languages.DUTCH)} className="dropdown-item">Nederland</div>
                </li>
                <li>
                  <div tabIndex={0} aria-label="Select spanish as a language" onClick={()=>onLanguageSelect(languages.SPANISH)} className="dropdown-item">Español</div>
                </li>
              </ul>
            </li>

           
          </ul>

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            {/* <span className="navbar-toggler-icon"></span> */}
            <i className="bi bi-search blue" style={{fontSize: "25px"}}></i>
          </button>
          <div className="collapse navbar-collapse" id="navbarSupportedContent">
            <div className="row cur-search-box full-width-on-mobile">
              <i className="bi bi-search cur-search-icon"></i>
              <input
                type="text"
                className="cur-search-input col-11"
                placeholder="Search here / Zoek hier / Busca aquí"
                onChange={(e)=>onSearchVUpdate(e)}
                onSubmit={()=>onSearchSubmit()}
              />
              <button onClick={()=>onSearchSubmit()} aria-label="Search button" className="cur-search-button col-1 remove-on-mobile">
                <img src="logoishdesign.svg" alt="" />
              </button>
            </div>
          </div>
        </div>
      </nav>
    </section>
  );
}
