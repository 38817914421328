import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import { LanguageContext } from "../context/TranslationContext";
import "../css/Cards.css";
import "../css/HotCards.css";
import { Random } from "../utils/array";
import { languageByNavigator } from "../utils/language";
import Screenshot from "./buttons/Screenshot";
interface TranslationCard {
  item: {
    title: string;
    english?: string;
    spanish?: string;
    dutch?: string;
    papiamentu?: string;
  };

  refIndex: number;
}
interface SearchCardsProps {
  data: TranslationCard[];
}
export default function SearchCards(props: SearchCardsProps) {
  const bgColors = [
    "linear-gradient(to right bottom, #1fefe6, #2d7dd0)",
    "linear-gradient(to bottom right, #e7ab86, #e78f86)",
    "linear-gradient(to bottom right, #ffc900, #ff5f00)",
    "linear-gradient(to bottom right, #f3e41e, #6bd02d, #25dfd6)",
    "linear-gradient(to bottom right, #ff6e60, #e7210d)",
    "linear-gradient(to right bottom, #1f2dda, #da1f86)",
  ];
  const random = new Random(bgColors);
  const {lang} = useContext(LanguageContext);
  let history = useHistory();
  return (
    <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
      {props.data.map((v, i) => {
        let id = `searchcard-${i}`;
        return (
          <div className="col" key={id}>
            <div
              className="card noise-light scale-in-center"
              id={id}
              style={{ backgroundImage: `${random.randomNoLastRepeat()}` }}
            >
              <div>
                <div onClick={()=>history.push(`/details/${v.refIndex}`)}className="hot-share-icon remove-on-ss clickable"><i className="bi bi-tools"></i></div>
                <Screenshot className="hot-insta-icon" id={id} removeShadow removeRadius customIcon="bi bi-instagram" customScreenshotClass="insta-center"/>
              <div className="w-100 d-flex"><img
                src="logoishdesign.png"
                className="card-img-top round-cur-logo clickable"
                alt=""
                onClick={()=>history.push(`/details/${v.refIndex}`)}
              /></div>
              <figure className="card-body text-center clickable" onClick={()=>history.push(`/details/${v.refIndex}`)}>
                <blockquote className="blockquote">
                  <p className="white fw-bold">{v.item.title}</p>
                </blockquote>

                <figcaption className="blockquote-footer white">
                  {languageByNavigator(v.item, lang)}
                </figcaption>

                <i className="white">papiamentu.org</i>
              </figure>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
}
