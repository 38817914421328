import React from "react";
export default function Sources() {
  return (
    <section className="container">
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2048720703689521"
     crossOrigin="anonymous"></script>
      <h1>
        <span className="pink">The book</span> <span className="grey">/</span>{" "}
        <span className="blue">E buki</span> <span className="grey">/</span>{" "}
        <span className="red">Het boek</span> <span className="grey">/</span>{" "}
        <span className="peach">El libro</span>
      </h1>
        
      <img style={{width: "100%"}} src="sapate_cover.jpg" alt="cover info" />
      <img style={{width: "100%"}} src="details.jpg" alt="legal information" />
      <img style={{width: "100%"}} src="bib.jpg" alt="bibliography" />


      <div>
          Sapaté, na bo sapatu!
Mil i un (1000 i 1)
ekspreshon / lokushon / refran / dicho / proverbio / paremia / adagio / anegin / sentensia / komparashon / máksima
pa mi pueblo
Dediká na:
Mi pueblo i famia Römer / Pereira / Richardson.
Promé edishon: yanüari 2011 Di dos edishon: desèmber 2012
Imprenta: “De Curaçaosche Courant” N.V. Copyright 2009
ISBN 978-99904-1-250-5
E derechonan ta reservá. No ta pèrmití pa reprodusí nada di e edishon akí di ningun forma, ni pa ningun medio, sin outorisashon previo i skribí di e outor.
</div>
 
    </section>
  );
}
