import React from "react";
import { Link } from "react-router-dom";
export default function Footer() {
  return (
    <div className="container">
      <footer className="d-flex flex-wrap justify-content-between align-items-center py-3 my-4 border-top">
        <p className="col-12 col-md-4 mb-0 text-muted">© 2009 ISBN 978-99904-1-250-5</p>

        <a
          href="https://github.com/duart38"
          className="col-6 col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none"
        >
          Website by Duart Snel
        </a>

        <ul className="nav col-6 col-md-4 justify-content-end">
          <li className="nav-item">
            <Link to="/" className="nav-link px-2 text-muted">
              Home
            </Link>
          </li>
          {/* <li className="nav-item">
            <Link to="/about" className="nav-link px-2 text-muted">
              About
            </Link>
          </li> */}
          <li className="nav-item">
            <Link to="/sources" className="nav-link px-2 text-muted">
              Credits
            </Link>
          </li>
        </ul>
      </footer>
    </div>
  );
}
