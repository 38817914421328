import React, { createContext, useContext, useState } from 'react'



type ContextProps = { 
  searchV: string,
  setSearchV: React.Dispatch<React.SetStateAction<string>>,
};
export const SearchContext = createContext<Partial<ContextProps>>({})

export const SearchContextProvider = (props: any) => {
  const [searchV, setSearchV] = useState("");

  return <SearchContext.Provider value={{ searchV, setSearchV }}>{props.children}</SearchContext.Provider>
}

export const useSearch = () => useContext(SearchContext)
