export function randomElement<T>(items: T[]){
    return items[Math.floor(Math.random()*items.length)];
}

export class Random<T> {
    public data: T[];
    private lastElement: number = -1;
    constructor(data: T[]){
        this.data = data;
    }


    findRandomIndex(n = this.data.length){
        return Math.floor(Math.random()*n);
    }
    static findRandomIdx(n:number){
        return Math.floor(Math.random()*n);
    }

    randomElement(){
        let idx = this.findRandomIndex();
        this.lastElement = idx;
        return this.data[idx];
    }

    private nonMutatingSplice(remove: number){
        let temp = [...this.data];
        temp.splice(remove, 1);
        return temp;
    }
    /**
     * Gets a random element that is not a repetition of the last element
     * */
    randomNoLastRepeat(): T {
        if(this.data.length === 1) return this.data[0];
        if(this.data.length === 0) throw new Error("Attempting to retrieve random element from empty array");

        let idx = this.findRandomIndex();
        if(this.lastElement === idx){

            let smaller = this.nonMutatingSplice(idx);
            let newIdx = this.findRandomIndex(smaller.length);

            this.lastElement = newIdx;
            return this.data[newIdx];
        }

        this.lastElement = idx;
        return this.data[idx];
    }
}