import Fuse from "fuse.js";
import React, { useContext } from "react";
import SearchCards from "../components/SearchCards";
import { SearchContext } from "../context/SearchContext";
import { data } from "../data/db";
import { objectKeyByLanguage } from "../utils/language";
import "../css/Animations.css"
import { LanguageContext } from "../context/TranslationContext";
export default function Search(props: any) {
  const { searchV } = useContext(SearchContext);
  const {lang} = useContext(LanguageContext);
  const fuse = new Fuse(data, {
    keys: ["title", objectKeyByLanguage(lang), "papiamentu"],
  });
  const results = fuse.search(searchV!, { limit: 15 });

  return (
    <section className="container">
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2048720703689521"
     crossOrigin="anonymous"></script>
      {searchV === "" ? (
        <div>
          <h2 className="text-center anim-updown-slow"><i className="bi bi-caret-up-fill"></i></h2>
          <h1 className="text-center">
            Put stuff up there
          </h1>
        </div>
      ) : null}
      <SearchCards data={results} />
    </section>
  );
}
