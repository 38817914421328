interface obj {
    english?: string;
    spanish?: string;
    dutch?: string;
    papiamentu?: string;
  }
export function languageByNavigator<T extends obj>(obj: T, preState = navigator.language): string | undefined{
    switch(preState){
        case "en-US":
        case "en": return obj.english;
        case "nl": return obj.dutch;
        case "es":
        case "la": return obj.spanish;
        default: return obj.english;
    }
}
export function objectKeyByLanguage(preState = navigator.language){
    switch(preState){
        case "en-US":
        case "en": return "english";
        case "nl": return "dutch";
        case "es":
        case "la": return "spanish";
        default: return "english";
    }
}