import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Footer from "./components/Footer";
import Nav from "./components/Nav";
import { SearchContextProvider } from "./context/SearchContext";
import { LanguageContextProvider } from "./context/TranslationContext";
import Details from "./pages/details";
import Home from "./pages/home";
import Search from "./pages/search";
import Sources from "./pages/sources";
import "./css/SAFARI.css";
import "./css/UTILS.css";
import AddTranslation from "./pages/addTranslation";

export default function App() {
  return (
    <Router>
      <SearchContextProvider>
        <LanguageContextProvider>
          <Nav />
          <br />
          <section className="container">
            <script
              async
              src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2048720703689521"
              crossOrigin="anonymous"
            ></script>
            <ins
              className="adsbygoogle"
              style={{ display: "block" }}
              data-ad-client="ca-pub-2048720703689521"
              data-ad-slot="2168026123"
              data-ad-format="auto"
              data-full-width-responsive="true"
            ></ins>
            <script>(adsbygoogle = window.adsbygoogle || []).push({});</script>
          </section>
          <Switch>
            <Route path="/search">
              <Search />
            </Route>
            <Route path="/details/:id">
              <Details />
            </Route>
            <Route path="/add">
              <AddTranslation />
            </Route>
            <Route path="/sources">
              <Sources />
            </Route>
            <Route path="/">
              <Home />
            </Route>
          </Switch>
        </LanguageContextProvider>
      </SearchContextProvider>
      <Footer />
    </Router>
  );
}
