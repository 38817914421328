import React from "react";
import "../../css/Animations.css";
export default function AnimatedFire(props: {childStyles?: React.CSSProperties}) {
  return (
    <span className="fire">
      <span style={props.childStyles} className="flame base">🔥</span>
      <span style={props.childStyles} className="flame animate">.</span>
      <span style={props.childStyles} className="flame animate">🔥</span>
      <span style={props.childStyles} className="flame animate">*</span>
    </span>
  );
}
